import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Beneficiary, BeneficiaryLookUpProperties } from './types/DTOs';
import generalFunctions from '../common/helpers/functions';
import beneficiaryFunctions from './BeneficiaryFunctions';
import ReactDOM from 'react-dom';
import Tippy from '@tippyjs/react';

const BeneficiaryLookup = (props: BeneficiaryLookUpProperties) => {
    const translations = JSON.parse(props.translations);
    const [suggestions, setSuggestions] = React.useState<Array<Beneficiary>>([]);
    const [selectedSuggestion, setSelectedSuggestion] = React.useState<number>(-1);
    const [beneficiary, setBeneficiary] = React.useState<Beneficiary>({ ID: 0, Name: '', AccountNumber: '', BankCode: '', BankName: '', Type: null, InternationalSupplement: null });
    const [value, setValue] = React.useState<string>(props.initialValue ?? '');

    //When accessing state from within a callback funtion, the state is always the initial value
    //This is a helper ref to go around that problem - use valueRef.current in callback functions for up-to-date state
    const valueRef = React.useRef(value);
    valueRef.current = value;

    const wrapperRef = React.useRef(null);
    generalFunctions.useOutsideAlerter(wrapperRef, () => setSuggestions([]));

    const emptyImgSrc = generalFunctions.urlContent(props.appPath, '/Content/Images/icons/img_trans.gif');

    const handleValueChanged = (value: string) => {
        setSelectedSuggestion(-1);
        if (value.length < 2) {
            setSuggestions([]);
            setValue(value);
            return;
        }
        setValue(value);

        const inputModel = {
            InputModel: {
                BeneficiaryName: value,
                BeneficiaryTypes: [props.type]
            }
        }

        fetch(generalFunctions.urlContent(props.appPath, '/Beneficiaries/SuggestionList'),
            {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    __RequestVerificationToken: props.antiforgeryToken.split('"')[5]
                },
                body: JSON.stringify(inputModel)
            })
            .then(response => {
                if (response.redirected) {
                    window.location.href = response.url;
                }
                return response.json()
            })
            .then(data => {
                setSuggestions(data.Beneficiaries);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const selectBeneficiary = (beneficiary: Beneficiary) => {
        setBeneficiary(beneficiary);
        setValue(beneficiary.Name);
        setSuggestions([]);
        beneficiaryFunctions.selectBeneficiary(beneficiary, 'triggerHackForBeneficiary');
    }

    const hightlight = (beneficiary: Beneficiary, value: string): string => {
        const label = `${beneficiary.Name} - ${beneficiary.AccountNumber}`;
        const matches = match(label, value, { findAllOccurrences: true, insideWords: true, requireMatchAll: true });
        const parts = parse(label, matches);
        let result = '';
        parts.forEach(part => result += part.highlight ? `<b>${part.text}</b>` : part.text)
        return result;
    }

      const handleKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape')
        {
            setSuggestions([]);
        }
          if (event.key === 'ArrowUp')
        {
            if (selectedSuggestion >= 0) {
                setSelectedSuggestion(selectedSuggestion - 1);
            }
        }
          if (event.key === 'ArrowDown')
        {
            if (selectedSuggestion < suggestions.length - 1) {
                setSelectedSuggestion(selectedSuggestion + 1);
            }
        }
        if (event.key === 'Enter')
        {
            selectBeneficiary(suggestions[selectedSuggestion]);
            event.preventDefault();
        }
    }

    let searchRef: HTMLInputElement;
    const suggestionsRef = React.createRef<HTMLUListElement>();
    React.useEffect(() => {
        if (suggestions && suggestions.length > 0) {
            suggestionsRef.current.style.width = searchRef.offsetWidth.toString() + 'px';
        }
    }, [suggestions]);

    //This last bit is really hacky, but again, is the price to pay to combine MVC + React
    const externalRef = React.useRef(null);
    const externalNameRef = React.useRef(null);

    const mutationObserver = (targetNode, handler) => {
        const config = { attributeFilter: ['class'] };

        const callback = function (mutationsList) {
            for (const mutation of mutationsList) {
                handler(mutation.oldValue);
            }
        };

        const observer = new MutationObserver(callback);

        observer.observe(targetNode, config);
    };

    React.useEffect(() => {
        mutationObserver(externalRef.current, myHandler);
        mutationObserver(externalNameRef.current, nameHandler);
    }, [])

    const nameHandler = () => {
        const node = document.getElementById('beneficiaryAccountNameCwnet');

        const domNode: any = ReactDOM.findDOMNode(node);
        if (domNode.value) {
            const benAccountName = domNode.value.trim()
            if (!valueRef.current) {
                setValue(benAccountName)
            }
        }
    }

    const myHandler = () => {
        const node = document.getElementById('bankinfoupdateLookup');
        const domNode: any = ReactDOM.findDOMNode(node);
        if (domNode.value) {
            const updatedBeneficiary = JSON.parse(domNode.value);
            setValue(updatedBeneficiary.Name);
            setBeneficiary(updatedBeneficiary);
            beneficiaryFunctions.selectBeneficiary(updatedBeneficiary, 'triggerHackForBeneficiary');
        }
    }

    return (
        <div className="l-section l-sectionReadwrite">
            <input ref={externalRef} type="hidden" className="triggerHackForBeneficiaryLookUp" />
            <input ref={externalNameRef} type="hidden" className="triggerHackForBeneficiaryNameLookUp" />
            <div className="l-sectionLeft">
                <span className="l-section-text">
                    <span>{translations.BeneficiaryName} </span>
                    {!!props.tooltipText &&
                        <Tippy placement="top" content={<span dangerouslySetInnerHTML={{ __html: props.tooltipText }}></span>} key="1" trigger="click" interactive={true} arrow={true} theme="quipu" maxWidth="350px" animation="shift-away" hideOnClick={true}>
                            <img src={emptyImgSrc} className="pointer border0 tooltip-ico valignmiddle" tabIndex={0} />
                        </Tippy>
                    }
                </span>
            </div>
            <div className="l-sectionRight" ref={wrapperRef} onKeyDown={e => handleKeyEvent(e)}>
                <DebounceInput autoComplete="off" maxLength={props.maxLength} id="beneficiaryName" data-beneficiary="BeneficiaryName" inputRef={ref => { searchRef = ref; }} debounceTimeout={400} name={props.beneficiaryNameHtmlName} onBlur={() => setValue(value.trim())} onChange={e => handleValueChanged(e.target.value)} value={value} placeholder={translations.SearchHint} />
                {suggestions && suggestions.length > 0 &&
                    <ul ref={suggestionsRef} className="select2-results suggestions">
                    {suggestions.map(s =>
                        <li onClick={() => selectBeneficiary(s)} className={selectedSuggestion >= 0 && suggestions[selectedSuggestion].ID === s.ID ? 'select2-results__option pointer active' : 'select2-results__option pointer'} key={s.ID} dangerouslySetInnerHTML={{ __html: hightlight(s, value) }}>
                        </li>
                    )}
                    </ul>
                }
                {props.hasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
            </div>
            <input type="hidden" id="bankinfoupdate" value={beneficiary && JSON.stringify(beneficiary)} />
        </div>
    );
}
export default BeneficiaryLookup;