import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import ReactDOM from 'react-dom';
import generalFunctions from '../common/helpers/functions';
import beneficiaryFunctions from './BeneficiaryFunctions';
import { Bank, BeneficiaryType } from './types/DTOs';
import Modal from 'react-modal';
import BeneficiaryBankTable from './BeneficiaryBankTable';

const BeneficiaryBankIntermediate = (props: any) => {
    const initialBank: Bank = props.selectedBank;
    const initialUseIntermediary: boolean = initialBank && (
        !!initialBank.LocalBankCode ||
        !!initialBank.BankCode ||
        !!initialBank.Address ||
        !!initialBank.Country ||
        !!initialBank.IntermediaryAccountNumber ||
        !!initialBank.BankName);

    const [useIntermediary, setUseIntermediary] = React.useState<boolean>(initialUseIntermediary);
    const [useSwift, setUseSwift] = React.useState<boolean>(!initialUseIntermediary || !!initialBank.BankCode);
    const [suggestions, setSuggestions] = React.useState<Array<Bank>>([]);
    const [selectedSuggestion, setSelectedSuggestion] = React.useState<number>(-1);
    const [selectedBank, setSelectedBank] = React.useState<Bank>(initialBank);
    const [value, setValue] = React.useState<string>(initialBank?.BankCode);
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
    const wrapperRef = React.useRef(null);
    const countrySelectRef = React.useRef(null);
    generalFunctions.useOutsideAlerter(wrapperRef, () => setSuggestions([]));
    const update = (value: string) => {
        setSelectedSuggestion(-1);
        if (value.length < 2) {
            setSuggestions([]);
            return;
        }

        setValue(value);
        beneficiaryFunctions.getBanksByNameOrBankCode({ BankNameOrBankCode: value }, props.appPath, data => setSuggestions(data.Banks));
    }

    const selectBank = (bank: Bank): void => {
        setSelectedBank(bank);
        setValue('');
        setSuggestions([]);
        setModalIsOpen(false);
    }

    const hightlight = (country: Bank, value: string): string => {
        const label = `${country.BIC} - ${country.BankName}`;
        const matches = match(label, value, { findAllOccurrences: true, insideWords: true, requireMatchAll: true });
        const parts = parse(label, matches);

        let result = '';
        parts.forEach(part => result += part.highlight ? `<b>${part.text}</b>` : part.text)
        return result;
    }

    const handleKeyEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.keyCode === 27) //Esc
        {
            setSuggestions([]);
        }
        if (event.keyCode === 38) //Up arrow
        {
            if (selectedSuggestion >= 0) {
                setSelectedSuggestion(selectedSuggestion - 1);
            }
        }
        if (event.keyCode === 40) //Down arrow
        {
            if (selectedSuggestion < suggestions.length - 1) {
                setSelectedSuggestion(selectedSuggestion + 1);
            }
        }
        if (event.keyCode === 13) //Enter
        {
            selectBank(suggestions[selectedSuggestion]);
            event.preventDefault();
        }
    }

    let searchRef: HTMLInputElement;
    const suggestionsRef = React.createRef<HTMLUListElement>();
    React.useEffect(() => {
        if (suggestions && suggestions.length > 0 && suggestionsRef.current) {
            suggestionsRef.current.style.width = searchRef.offsetWidth.toString() + 'px';
        }
    }, [suggestions]);

    //This last bit is really hacky, but again, is the price to pay to combine MVC + React
    const externalRef = React.useRef(null);
    const mutationObserver = (targetNode, handler) => {
        const config = { attributeFilter: ['class'] };

        const callback = function (mutationsList) {
            for (const mutation of mutationsList) {
                handler(mutation.oldValue);
            }
        };

        const observer = new MutationObserver(callback);

        observer.observe(targetNode, config);
    };

    React.useEffect(() => {
        if (countrySelectRef.current !== null) {
                if ($(countrySelectRef.current).hasClass('select2-hidden-accessible')) {
                    $(countrySelectRef.current).select2('destroy');
                }

            $(countrySelectRef.current).select2().off('change').on('change', e => {
                const selectNode = e.target as HTMLSelectElement;
                setSelectedBank({ ...selectedBank, Country: selectNode.value, CountryLabel: selectNode.options[selectNode.selectedIndex].text })
            });
        }
    }, [useSwift, selectedBank])

    React.useEffect(() => {
        mutationObserver(externalRef.current, handleBankUpdate)
    }, [])
    //

    const handleBankUpdate = () => {
        const node = document.getElementById('bankinfoupdate');
        const domNode: any = ReactDOM.findDOMNode(node);
        if (domNode && domNode.value) {
            const updatedBeneficiary = JSON.parse(domNode.value);
            const bankToUpdate: Bank = {
                Address: updatedBeneficiary.InternationalSupplement?.IntermediaryBankAddress,
                BankCode: updatedBeneficiary.InternationalSupplement?.IntermediaryBankBIC,
                BankName: updatedBeneficiary.InternationalSupplement?.IntermediaryBankName,
                BIC: updatedBeneficiary.InternationalSupplement?.IntermediaryBankBIC,
                Country: updatedBeneficiary.InternationalSupplement?.IntermediaryBankCountryIsoCode,
                CountryLabel: updatedBeneficiary?.IntemediaryBankCountryLabel,
                IntermediaryAccountNumber: updatedBeneficiary.InternationalSupplement?.IntermediaryAccountNumber,
                LocalBankCode: updatedBeneficiary.InternationalSupplement?.IntermediaryLocalBankCode
            }
            setUseSwift(!!bankToUpdate.BankCode);
            setSelectedBank(bankToUpdate);
            setValue(bankToUpdate.BIC ?? '');
            setUseIntermediary(!!bankToUpdate.LocalBankCode || !!bankToUpdate.BankCode);
        }
    }

    const toggleSwift = (checked: boolean) => {
        if ($(countrySelectRef.current).hasClass('select2-hidden-accessible')) {
            $(countrySelectRef.current).select2('destroy');
        }
        setUseSwift(checked);
        setValue('');
        setSelectedBank({
            Address: '',
            BankBranch: '',
            BankCode: '',
            BankName: '',
            BIC: '',
            BLZ: '',
            City: '',
            Country: '',
            CountryLabel: '',
            Location: '',
            IntermediaryAccountNumber: selectedBank.IntermediaryAccountNumber,
            LocalBankCode: ''
        })
    }

    const clearIntermediaryBank = () => {
        setUseIntermediary(false);
        setSelectedBank(null);
        setValue('');
    }

    return (
        <div className="box-section">
            {useSwift &&
                <React.Fragment>
                    <input type="hidden" name={props.htmlNames.BankCodeHtmlName} value={selectedBank?.BankCode ?? ''} />
                    <input type="hidden" name={props.htmlNames.BankNameHtmlName} value={selectedBank?.BankName ?? ''} />
                    <input type="hidden" name={props.htmlNames.BankAddressHtmlName} value={selectedBank?.Address ?? ''} />
                    <input type="hidden" name={props.htmlNames.BankCountryHtmlName} value={selectedBank?.Country ?? ''} />
                    <input type="hidden" name={props.htmlNames.BankCountryLabelHtmlName} value={selectedBank?.CountryLabel ?? ''} />
                </React.Fragment>
            }
            <input ref={externalRef} type="hidden" className="triggerHackForBeneficiary" />
            <input type="hidden" name={props.htmlNames.BankCountryLabelHtmlName} value={selectedBank?.CountryLabel ?? ''} />
            {!useIntermediary &&
                <div className="l-section l-sectionReadwrite">
                    <span onClick={() => setUseIntermediary(true)} className="underlined corporatecolor pointer l-section-text">{props.translations.ShowIntermediaryBankText}</span>
                </div>
            }
            {useIntermediary &&
                <div>
                    <div className="l-section l-sectionReadwriteHeader">
                        {props.translations.IntermediateBankInfoTitle}
                        <i>({props.translations.OptionalTitle})</i>
                        <button onClick={() => clearIntermediaryBank()} className="valigntop imgDelete margin-left5"></button>
                    </div>
                    <div className="l-section l-sectionReadwrite">
                        <div className="l-sectionLeft">
                            {props.translations.UseSwift}
                        </div>
                        <div className="l-sectionRight">
                            <input type="checkbox" checked={useSwift} onChange={e => toggleSwift(e.target.checked)} />
                        </div>
                    </div>
                    {useSwift &&
                        <div className="l-section l-sectionReadwrite">
                            <div className="l-sectionLeft">
                                <span className="l-section-text">
                                    <input type="button" className="buttonAsLink l-section-text" value={props.translations.IntermediateSwift} onClick={() => setModalIsOpen(true)} />
                                </span>
                                <Modal isOpen={modalIsOpen} className="react-modal" overlayClassName="react-modal-overlay" onRequestClose={() => setModalIsOpen(false)} shouldCloseOnOverlayClick={true}>
                                    <BeneficiaryBankTable beneficiaryType={BeneficiaryType.International} appPath={props.appPath} translations={props.translations} bankPageSize={props.bankPageSize} updateBank={selectBank} closeModal={() => setModalIsOpen(false)} />
                                </Modal>
                            </div>

                            <div className="l-sectionRight" ref={wrapperRef} onKeyDown={e => handleKeyEvent(e)}>
                                {!selectedBank?.BankCode &&
                                    <React.Fragment>
                                        <DebounceInput maxLength={props.htmlNames.BankCodeMaxLength} inputRef={ref => { searchRef = ref; }} debounceTimeout={400}
                                            onChange={e => update(e.target.value)} value={value} placeholder={props.translations.TypeToSearch} />
                                        {suggestions && suggestions.length > 0 &&
                                            <ul ref={suggestionsRef} className="select2-results suggestions">
                                                {suggestions.map(s =>
                                                    <li onClick={() => selectBank(s)} className={selectedSuggestion >= 0 && suggestions[selectedSuggestion].BIC === s.BIC ? 'active select2-results__option pointer' : 'select2-results__option pointer'} key={s.BankCode} dangerouslySetInnerHTML={{ __html: hightlight(s, value) }}>

                                                    </li>
                                                )}
                                            </ul>
                                        }
                                    </React.Fragment>
                                }
                                {selectedBank?.BankCode &&
                                    <span className="l-section-text inline-block">
                                        {selectedBank?.BankCode} <button onClick={() => selectBank(null)} name="removeIntermediate" className="valigntop imgDelete"></button>
                                    </span>}
                                {props.errors.BankCodeHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                            </div>
                        </div>
                    }
                    {!useSwift &&
                        <div className="l-section l-sectionReadwrite">
                            <div className="l-sectionLeft">
                                <span className="l-section-text">
                                    {props.translations.BeneficiaryLocalBankCode}
                                </span>
                            </div>
                            <div className="l-sectionRight">
                                <input type="text" name={props.htmlNames.LocalBankCodeHtmlName} maxLength={props.htmlNames.LocalBankCodeMaxLength} value={selectedBank?.LocalBankCode ?? ''} onChange={e => setSelectedBank({ ...selectedBank, LocalBankCode: e.target.value })} />
                                {props.errors.BeneficiaryLocalBankCodeHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                            </div>
                        </div>
                    }
                    <div className="l-section l-sectionReadwrite">
                        <div className="l-sectionLeft">
                            <span className="l-section-text">
                                {props.translations.IntermediateBank}
                            </span>
                        </div>
                        <div className="l-sectionRight">
                            {!useSwift &&
                                <input type="text" name={props.htmlNames.BankNameHtmlName} maxLength={props.htmlNames.BankNameMaxLength} value={selectedBank?.BankName ?? ''} onChange={e => setSelectedBank({ ...selectedBank, BankName: e.target.value })} />
                            }
                            {useSwift &&
                                <span className="l-section-text">
                                    {selectedBank?.BankName}
                                </span>
                            }
                            {props.errors.BankNameHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                        </div>
                    </div>
                    <div className="l-section l-sectionReadwrite">
                        <div className="l-sectionLeft">
                            <span className="l-section-text">
                                {props.translations.BeneficiaryBankAddress}
                            </span>
                        </div>
                        <div className="l-sectionRight">
                            {!useSwift &&
                                <input type="text" name={props.htmlNames.BankAddressHtmlName} maxLength={props.htmlNames.BankAddressMaxLength} value={selectedBank?.Address ?? ''} onChange={e => setSelectedBank({ ...selectedBank, Address: e.target.value })} />
                            }
                            {useSwift &&
                                <span className="l-section-text">
                                    {selectedBank?.Address}
                                </span>
                            }
                            {props.errors.BankAddressHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                        </div>
                    </div>
                    <div className="l-section l-sectionReadwrite">
                        <div className="l-sectionLeft">
                            <span className="l-section-text">
                                {props.translations.BeneficiaryBankCountry}
                            </span>
                        </div>
                        <div className="l-sectionRight">
                            {!useSwift && props.countries?.length > 0 &&
                                <select ref={countrySelectRef} name={props.htmlNames.BankCountryHtmlName} value={selectedBank?.Country ?? ''} onChange={e => setSelectedBank({ ...selectedBank, Country: e.target.value })}>
                                    {[{ ThreeLetterName: '', FullName: props.translations.EmptySelectLabel }, ...props.countries].map(c =>
                                        <option key={c.ThreeLetterName} value={c.ThreeLetterName}>
                                            {c.FullName}
                                        </option>
                                    )}
                                </select>
                            }
                            {useSwift &&
                                <span className="l-section-text">
                                    {selectedBank?.CountryLabel}
                                </span>
                            }
                            {props.errors.BankCountryHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                        </div>
                    </div>
                    <div className="l-section l-sectionReadwrite">
                        <div className="l-sectionLeft">
                            <span className="l-section-text">
                                {props.translations.IntermediateAccount}
                            </span>
                        </div>
                        <div className="l-sectionRight">
                            <input type="text" name={props.htmlNames.IntermediaryAccountNumberHtmlName} maxLength={props.htmlNames.IntermediaryAccountNumberMaxLength} value={selectedBank?.IntermediaryAccountNumber ?? ''} onChange={e => setSelectedBank({ ...selectedBank, IntermediaryAccountNumber: e.target.value })} />
                            {props.errors.IntermediaryAccountNumberHasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                        </div>
                    </div>
                </div >
            }
        </div>
    );
}
export default BeneficiaryBankIntermediate;