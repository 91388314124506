import * as React from 'react';
import { Beneficiary, BeneficiaryAction, BeneficiaryProps, BeneficiaryType, BeneficiaryTypeOptions } from './types/DTOs';
import Modal from 'react-modal';
import generalFunctions from '../common/helpers/functions';
import BeneficiaryListTable from './BeneficiaryListTable';
import beneficiaryFunctions from './BeneficiaryFunctions';

const BeneficiaryList = (props: BeneficiaryProps) => {
    const beneficiaryList = JSON.parse(props.beneficiaries ?? '');
    const beneficiaryTypeOptions: Array<BeneficiaryTypeOptions> = JSON.parse(props.beneficiaryTypeOptions);
    const translations = JSON.parse(props.translations);
    const [selectedBeneficiary, setSelectedBeneficiary] = React.useState<Beneficiary>({ ID: 0, Name: '', AccountNumber: '', BankCode: '', BankName: '', Type: null, InternationalSupplement: null });
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false);
    const [beneficiaryType, setBeneficiaryTypes] = React.useState<BeneficiaryType>(props.type);

    const updateBeneficiaryType = (type: BeneficiaryType): void => {
        history.pushState({}, '', generalFunctions.urlContent(props.appPath, `/Beneficiaries?type=${type}`));
        setBeneficiaryTypes(type);
    }

    const getClassForCategory = (type: BeneficiaryType): string => {
        return beneficiaryType === type ? 'active' : 'inactive';
    }

    React.useEffect(() => {
        if (typeof (window) !== 'undefined') {
            Modal.setAppElement('#maincontentwrapper')
        }
    }, [])

    const generateLinkByAction = (beneficiaryOrType: Beneficiary | BeneficiaryType, action: BeneficiaryAction): string => {
        let link: string;
        const beneficiary = beneficiaryOrType as Beneficiary;
        const type = beneficiaryOrType as BeneficiaryType;
        switch (action) {
            case BeneficiaryAction.Create:
                link = `/Beneficiaries/${action}?type=${type}`;
                break;
            case BeneficiaryAction.Edit:
            case BeneficiaryAction.Delete:
                link = `/Beneficiaries/${action}?BeneficiaryId=${beneficiary.ID}`;
                break;
            case BeneficiaryAction.UseInTransfer:
                link = `OrderV2/Create?OrderType=${beneficiary.Type}&BeneficiaryId=${beneficiary.ID}`;
                break;

        }
        return generalFunctions.urlContent(props.appPath, link);
    }

    const updateBeneficiary = (beneficiary: Beneficiary): void => {
        setSelectedBeneficiary(beneficiary);
        setModalIsOpen(false);
        beneficiaryFunctions.selectBeneficiary(beneficiary, 'triggerHackForBeneficiaryLookUp');
    }
    const getBeneficiaryTypeOptions = (beneficiaryType: BeneficiaryType): BeneficiaryTypeOptions => {
        return {
            Type: beneficiaryType, HasTaxCode: beneficiaryTypeOptions.find(x => x.Type === beneficiaryType).HasTaxCode,
        };
    }

    return (
        <React.Fragment>
            {!props.isPopup &&
                <form id="frmSearchBeneficiaries">
                    <ul className="l-section taggedList horizontallistblock">
                        {beneficiaryTypeOptions.map(b =>
                            <li key={b.Type} className={getClassForCategory(b.Type)} onClick={() => updateBeneficiaryType(b.Type)}>{translations[b.Type]}</li>
                        )}
                </ul>
                    <div className="l-section extramargin">
                        <a className="btnAdd" href={generateLinkByAction(beneficiaryType, BeneficiaryAction.Create)}>{translations.AddBeneficiary.replace('{0}', translations[beneficiaryType].toLocaleLowerCase())}</a>
                    </div>
                    <BeneficiaryListTable appPath={props.appPath} beneficiaries={beneficiaryList} translations={translations} beneficiaryTypeOptions={getBeneficiaryTypeOptions(beneficiaryType)}
                        isPopup={props.isPopup} beneficiaryPageSize={props.beneficiaryPageSize} antiforgeryToken={props.antiforgeryToken} />
                </form>
            }
            {props.isPopup &&
                <React.Fragment>
                    <input type="hidden" id="bankinfoupdateLookup" value={selectedBeneficiary && JSON.stringify(selectedBeneficiary)} />
                    <div className="l-section l-sectionReadwrite">
                        <div className="l-sectionLeft">
                            <span className="l-section-text"><input type="button" className="buttonAsLink l-section-text" value={translations.SelectBeneficiary} onClick={() => setModalIsOpen(true)} /></span>
                        </div>
                    </div>
                    <Modal isOpen={modalIsOpen} className="react-modal" overlayClassName="react-modal-overlay" onRequestClose={() => setModalIsOpen(false)} shouldCloseOnOverlayClick={true}>
                        {modalIsOpen &&
                        <form id="frmSearchBeneficiaries">
                        <BeneficiaryListTable appPath={props.appPath} beneficiaries={beneficiaryList} translations={translations} closeModal={() => setModalIsOpen(false)}
                                    beneficiaryTypeOptions={getBeneficiaryTypeOptions(beneficiaryType)} isPopup={props.isPopup} updateBeneficiary={updateBeneficiary}
                                    beneficiaryPageSize={props.beneficiaryPageSize} antiforgeryToken={props.antiforgeryToken} />
                            </form >
                        }
                    </Modal>
                </React.Fragment>
            }
        </React.Fragment >
    );
}
export default BeneficiaryList;