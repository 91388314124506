import ReactDOM from 'react-dom';
import { Bank, BankList, BanksWithCount, Beneficiary, BeneficiaryType, Country, FilterBankModel } from './types/DTOs';
import generalFunctions from '../common/helpers/functions';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

const selectBeneficiary = (beneficiary: Beneficiary, triggerClass: string) => {
    setDOMNode('BeneficiaryId', beneficiary.ID.toString());
    setDOMNode('BeneficiaryName', beneficiary.Name);
    setDOMNode('BeneficiaryAccount', beneficiary.AccountNumber);
    setDOMNode('BeneficiaryAddress', beneficiary.InternationalSupplement?.BeneficiaryAddress ?? beneficiary.Properties?.BeneficiaryAddress1);
    setDOMNode('BeneficiaryCountryIsoCode', beneficiary.Properties?.BeneficiaryCountryIsoCode ?? '');
    setDOMNode('ExistsBeneficiaryTaxCode', !!beneficiary.Properties?.BeneficiaryTaxCode);
    setDOMNode('BeneficiaryTaxCode', beneficiary.Properties?.BeneficiaryTaxCode ?? '');
    setDOMNode('BeneficiaryPassport', beneficiary.Properties?.BeneficiaryPassport ?? '');
    setDOMNode('BeneficiaryResident', beneficiary.Properties?.IsResident === undefined ? '' : !!beneficiary.Properties?.IsResident);
    triggerUpdate(triggerClass);
}

const triggerUpdate = (triggerClass: string) => {
    const node: NodeListOf<Element> = document.querySelectorAll(`.${triggerClass}`);
    Array.from(node).forEach(n => {
        const domNode: any = ReactDOM.findDOMNode(n);
        if (domNode) {
            Array.from(domNode.classList).exist(c => c === 'trigger') ? domNode.classList.remove('trigger') : domNode.classList.add('trigger');
        }
    });
}

const setDOMNode = (id: string, value: string | boolean) => {
    if (value === null || value === undefined) { return; }

    const nodeList = document.querySelectorAll(`[data-beneficiary="${id}"]`); //document.getElementById(id);
    // Using findDOMNode is considered bad practice but,
    // to achieve without using this we should migrate all
    // beneficiary input models to React, which won't happen
    for (const node of Array.from(nodeList)) {
        const domNode: any = ReactDOM.findDOMNode(node);

        if (domNode) {
            if (domNode.type === 'checkbox') {
                domNode.checked = value;
            } else if (domNode.type === 'text' || domNode.type === 'hidden' || domNode.type === 'select-one' || domNode.type === 'select' || domNode.type ==='textarea') {
                domNode.value = value;
            } else {
                domNode.innerText = value;
            }
            domNode.dispatchEvent(new Event('change')); // Necessary for select2 to update the value
        }
    }
}

const updateCountries = (appPath: string, callback: (data: Array<Country>) => void) => {
    fetch(generalFunctions.urlContent(appPath, '/Beneficiaries/GetIsoCountries'),
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then((data: Array<Country>) => {
            callback(data);
        });
}

const getInternationalBanksByNameOrBankCode = (filter: FilterBankModel, appPath: string, callback: (data: BanksWithCount) => void) => {
    filter.BeneficiaryType = BeneficiaryType.International;
    return getBanksByNameOrBankCode(filter, appPath, callback);
}

const getNationalBanksByNameOrBankCode = (filter: FilterBankModel, appPath: string, callback: (data: BanksWithCount) => void) => {
    filter.BeneficiaryType = BeneficiaryType.National;
    return getBanksByNameOrBankCode(filter, appPath, callback);
}

const getBanksByNameOrBankCode = (filter: FilterBankModel, appPath: string, callback: (data: BanksWithCount) => void) => {
    if (!filter.BeneficiaryType) {
        throw new Error('Beneficiary type is required for call GetBanksByBankCodeOrName');
    }

    const inputModel = {
        InputModel: filter
    }
    fetch(generalFunctions.urlContent(appPath, '/Beneficiaries/GetBanksByBankCodeOrName'),
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inputModel)
        })
        .then(response => {
            if (response.redirected) {
                window.location.href = response.url;
            }
            return response.json()
        })
        .then((data: BankList) => callback(data.BankList));
}

const hightlightBank = (country: Bank, value: string): string => {
    const label = `${country.BIC} - ${country.BankName}`;
    const matches = match(label, value, { findAllOccurrences: true, insideWords: true, requireMatchAll: true });
    const parts = parse(label, matches);

    let result = '';
    parts.forEach(part => result += part.highlight ? `<b>${part.text}</b>` : part.text)
    return result;
}

export default {
    selectBeneficiary,
    triggerUpdate,
    updateCountries,
    getInternationalBanksByNameOrBankCode,
    getNationalBanksByNameOrBankCode,
    hightlightBank,
    getBanksByNameOrBankCode
}