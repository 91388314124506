import * as React from 'react';
import { DebounceInput } from 'react-debounce-input';
import ReactDOM from 'react-dom';
import generalFunctions from '../common/helpers/functions';
import beneficiaryFunctions from './BeneficiaryFunctions';
import { Bank, BeneficiaryAccountProps, BeneficiaryType } from './types/DTOs';
import { BeneficiarySubtype } from './types/DTOs'

const BeneficiaryAccount = (props: BeneficiaryAccountProps) => {
    const translations = JSON.parse(props.translations);
    const [initialUpdate, setInitialUpdate] = React.useState<boolean>(!props.initialValue);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>(props.initialValue ?? '');
    const [bank, setBank] = React.useState<Bank>({});
    const [beneficiaryAccountNameCwnet, setBeneficiaryAccountNameCwnet] = React.useState<string>(props.beneficiaryAccountNameCwnetValue ?? '');

    let initialInfoMessage = '';
    if (props.infoMessageValue && props.infoMessageValue !== translations.InvalidBeneficiaryAccount) {
        //Clear up initial message invalid beneficiary account, otherwise it will appear twice in the page
        initialInfoMessage = props.infoMessageValue;
    }
    const [infoMessage, setInfoMessage] = React.useState<string>(initialInfoMessage);

    const [inputPlaceholder, setInputPlaceholder] = React.useState<string>(translations.BeneficiaryAccountHint);
    const externalRefSubtype = React.useRef(null);
    const externalRefAccount = React.useRef(null);

    React.useEffect(() => {
        //second check required in case we press back on payment details
        //If not then bank will stay null and we will incorrectly use setInfoMessageIfBankNotDerived
        if (!value || (!initialUpdate && !value)) {
            setInitialUpdate(true);
            return;
        }

        const cleanedValue = value.replace(/ /g, '').toUpperCase();
        setValue(cleanedValue);
        setInfoMessage('');
        setBeneficiaryAccountNameCwnet('');

        if (bankNeedsUpdate()) {
            updateBankDetails(cleanedValue, props.beneficiaryType);
        }
    }, [value]);

    const bankNeedsUpdate = (): boolean => {
        if (props.beneficiaryType !== BeneficiaryType.International) {
            return true;
        }
        const nodeIomtSubtype = document.getElementById('beneficiarySubtype');
        const domNodeIomtSubtype: any = ReactDOM.findDOMNode(nodeIomtSubtype);
        if (domNodeIomtSubtype && domNodeIomtSubtype.value) {
            const subtype: BeneficiarySubtype = domNodeIomtSubtype.value;
            return subtype === BeneficiarySubtype.IBAN;
        }
        return false;
    }

    const setInfoMessageIfBankNotDerived = (): void => {
        if (!bank && !!value) {
            setInfoMessage(translations.InvalidBeneficiaryAccount);
        }
    }

    const updateBankDetails = (value: string, beneficiaryType: BeneficiaryType): void => {
        setBank({});
        beneficiaryFunctions.triggerUpdate('triggerHackForBank');
        setLoading(true);
        fetch(generalFunctions.urlContent(props.appPath, '/Beneficiaries/GetAccountDetails'),
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    accountNumber: value,
                    beneficiaryType: beneficiaryType
                })
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                setLoading(false);
            })
            .then(data => {
                setTimeout(() => {
                    setBank(data.Bank);
                    setBeneficiaryAccountNameCwnet(data.AccountIdentifier?.AccountName);
                    setInfoMessage(data.WarningMessages?.join(' '));
                    beneficiaryFunctions.triggerUpdate('triggerHackForBank');
                    beneficiaryFunctions.triggerUpdate('triggerHackForBeneficiaryNameLookUp');
                    setLoading(false);
                }, 500);
            })
    }

    const substituteBlanksOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {

        const input = event.target as HTMLInputElement;

        const start = input.selectionStart;
        const end = input.selectionEnd;

        const before = value ? value.slice(0, start) : '';
        const after = value ? value.slice(end) : '';

        let newValue = before + event.clipboardData.getData('Text').replace(/ /g, '') + after;
        newValue = newValue.toUpperCase();

        if (props.maxLength) {
            newValue = newValue.substr(0, Number.parseInt(props.maxLength));
        }

        setValue(newValue);
        event.preventDefault();
    }

    const mutationObserver = (targetNode, handler) => {
        const config = { attributeFilter: ['class'] };

        const callback = function (mutationsList) {
            for (const mutation of mutationsList) {
                handler(mutation.oldValue);
            }
        };

        const observer = new MutationObserver(callback);

        observer.observe(targetNode, config);
    };

    React.useEffect(() => {
        mutationObserver(externalRefSubtype.current, subtypeUpdateHandler)
        if (props.showAccountNameCwnet) {
            mutationObserver(externalRefAccount.current, accountUpdateHandler)
        }
    }, [])

    const subtypeUpdateHandler = () => {
        const nodeIomtSubtype = document.getElementById('beneficiarySubtype');
        const domNodeIomtSubtype: any = ReactDOM.findDOMNode(nodeIomtSubtype);
        if (domNodeIomtSubtype && domNodeIomtSubtype.value) {
            const selectedSubtype: BeneficiarySubtype = domNodeIomtSubtype.value;
            const accountNode = document.getElementById('beneficiaryPrimaryAccount'); //Mutation and react state don't work together
            const domNodeAccount: any = ReactDOM.findDOMNode(accountNode); //so using DOM directly

            setValue(domNodeAccount.value);
            if (selectedSubtype === BeneficiarySubtype.IBAN) {
                if (domNodeAccount.value) {
                    updateBankDetails(domNodeAccount.value, props.beneficiaryType);
                }
                setInputPlaceholder(translations.BeneficiaryAccountHint);
            } else {
                setInputPlaceholder('');
            }
        }
    }

    const accountUpdateHandler = () => {
        const node = document.getElementById('bankinfoupdate');
        const domNode: any = ReactDOM.findDOMNode(node);
        if (domNode.value) {
            const updatedBeneficiary = JSON.parse(domNode.value);
            setValue(updatedBeneficiary.AccountNumber);
        }
    }

    return (
        <React.Fragment>
            <div className="l-section l-sectionReadwrite">
                <input ref={externalRefSubtype} type="hidden" className="triggerHackForSubtype" />
                <div className="l-sectionLeft">
                    <span className="l-section-text">
                        {translations.BeneficiaryAccount}
                    </span>
                </div>
                <div className="l-sectionRight">
                    <DebounceInput maxLength={props.maxLength} id="beneficiaryPrimaryAccount" onPaste={e => substituteBlanksOnPaste(e)} className={loading ? 'loader' : ''} data-beneficiary="BeneficiaryAccount" debounceTimeout={400} name={props.beneficiaryAccountHtmlName} onChange={e => setValue(e.target.value)} value={value} placeholder={inputPlaceholder} />
                    {props.hasErrors && <span className="field-validation-error margin-left3 inline-block">*</span>}
                </div>
                <input type="hidden" id="beneficiaryBank" value={JSON.stringify(bank)} />
            </div>
            {props.showAccountNameCwnet &&
                <React.Fragment>
                    <input ref={externalRefAccount} type="hidden" className="triggerHackForBeneficiary" />
                    <div className="l-section l-sectionReadwrite" >
                        <div className="l-sectionLeft">
                            <span className="l-section-text">
                                &nbsp;
                            </span>
                        </div>
                        <div className="l-sectionRight">
                            <span className="l-section-text">
                                <img id="VerifyAccountNumber" className="pointer backgroundcolor-FFFFFF valignbottom border0 is-inline" onClick={() => setInfoMessageIfBankNotDerived()} src={generalFunctions.urlContent(props.appPath, '/Content/Images/Check-availability.png')} />
                                <span id="errorTexts" className="l-section-text-noleft errorcolor is-inline">{infoMessage}</span>
                                <input type="hidden" name={props.infoMessageHtmlName} value={infoMessage} />
                            </span>
                        </div>
                    </div >
                    <div className="l-section l-sectionReadwrite" >
                        <div className="l-sectionLeft">
                            <span className="l-section-text">
                                {translations.BeneficiaryAccountName}
                            </span>
                        </div>
                        <div className="l-sectionRight">
                            <span className="l-section-text">
                                {beneficiaryAccountNameCwnet}
                                <input type="hidden" id="beneficiaryAccountNameCwnet" name={props.beneficiaryAccountNameCwnetHtmlName} value={beneficiaryAccountNameCwnet} />
                            </span>
                        </div>
                    </div>
                </React.Fragment >
            }
        </React.Fragment >
    );
}
export default BeneficiaryAccount;