import * as React from 'react';
import { BankingContractAmountDatesProps, BankingContractAmountDate } from './types/DTOs';

const BankingContractAmountDateTable = (props: BankingContractAmountDatesProps) => {
    const translations = JSON.parse(props.translations);
    const amountDateList: Array<BankingContractAmountDate> = JSON.parse(props.bankingContractAmountDateListJson);
    const currencies: Array<string> = [];
    const [amountDecimal, setAmountDecimal] = React.useState<number>();
    const [amountFraction, setAmountFraction] = React.useState<number>();
    const [amountCurrency, setAmountCurrency] = React.useState<string>();
    const [amountType, setAmountType] = React.useState<string>();

    amountDateList?.forEach(x => {
        for (const key in x.CurrencyAmounts) {
            !currencies.includes(key) && currencies.push(key);
        }
    })

    const UpdateAmount = (amount: number, currency: string, amountType: string): void => {
        setAmountDecimal(Math.floor(amount));
        setAmountFraction(Math.round((amount % 1) * 100));
        setAmountCurrency(currency);
        setAmountType(amountType);
        console.log(amount + currency);
    }

    const UpdateAmountDecimalFreeInput = (amountInt: number, currency: string): void => {
        setAmountDecimal(amountInt);
        setAmountCurrency(currency);
    }

    const UpdateAmountFractionFreeInput = (amountFraction: number, currency: string): void => {
        setAmountFraction(amountFraction);
        setAmountCurrency(currency);
    }

    const CheckRadioButton = (amount: number, currency: string, amountTypeSelected: string): boolean => {
        return (amountDecimal === Math.floor(amount) && amountFraction === Math.round((amount % 1) * 100) && amountCurrency === currency && amountType === amountTypeSelected);
    }

    return (
        <React.Fragment>
            <input type="hidden" name={props.htmlInputAmountIntegerName} value={amountDecimal} />
            <input type="hidden" name={props.htmlInputAmountFractionName} value={amountFraction} />
            <input type="hidden" name={props.htmlInputCurrencyName} value={amountCurrency} />

            <div className="l-section l-sectionReadonly">
                <table className="table-default xxlarge">
                    <colgroup>
                        <col className="width28" />
                        <col className="width20" />
                        <col className="width4" />
                        <col className="width20" />
                        <col className="width4" />
                        <col className="width20" />
                    </colgroup>
                    <thead>
                        <th></th>
                        <th>{translations.LimitDate}</th>
                        <th colSpan={2}>{translations.NIO}</th>
                        <th colSpan={2}>{translations.USD}</th>
                    </thead>
                    <tbody>
                        {amountDateList?.map(amountdate =>
                            <tr key={amountdate.AmountType}>
                                <td>{amountdate.AmountType}</td>
                                <td>{amountdate.ContractDateLabel}</td>
                                {currencies?.map(currency =>
                                    <td colSpan={2} key={currency}>
                                        <input type="radio"
                                            onClick={() => UpdateAmount(amountdate.CurrencyAmounts[currency], currency, amountdate.AmountType)}
                                            checked={CheckRadioButton(amountdate.CurrencyAmounts[currency], currency, amountdate.AmountType)} />
                                        {currency in amountdate.CurrencyAmounts ? ' ' + amountdate.CurrencyAmounts[currency] + ' ' + currency : ' 0' + currency}
                                    </td>
                                )}
                            </tr>
                        )}
                        <tr>
                            <td colSpan={2}>{translations.Amount}</td>
                            {currencies?.map(currency =>
                                <React.Fragment key={currency}>
                                    <td><input type="radio" /></td>
                                    <td className="alignright">
                                        <ul className="horizontallist xsmallseparation">
                                            <li><input type="text" maxLength={9} onChange={e => UpdateAmountDecimalFreeInput(e.target.valueAsNumber, currency)} /></li>
                                            <li>{props.currentCultureSeparator}</li>
                                            <li><input type="text" maxLength={2} onChange={e => UpdateAmountFractionFreeInput(e.target.valueAsNumber, currency)} value="00" /></li>
                                        </ul>
                                    </td>
                                </React.Fragment>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}
export default BankingContractAmountDateTable;